// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-paths-about-page-index-jsx": () => import("./../../../src/pages/paths/about_page/index.jsx" /* webpackChunkName: "component---src-pages-paths-about-page-index-jsx" */),
  "component---src-pages-paths-contact-page-index-jsx": () => import("./../../../src/pages/paths/contact_page/index.jsx" /* webpackChunkName: "component---src-pages-paths-contact-page-index-jsx" */),
  "component---src-pages-paths-home-index-jsx": () => import("./../../../src/pages/paths/home/index.jsx" /* webpackChunkName: "component---src-pages-paths-home-index-jsx" */),
  "component---src-pages-paths-our-values-page-index-jsx": () => import("./../../../src/pages/paths/our_values_page/index.jsx" /* webpackChunkName: "component---src-pages-paths-our-values-page-index-jsx" */),
  "component---src-pages-paths-privacy-page-index-jsx": () => import("./../../../src/pages/paths/privacy_page/index.jsx" /* webpackChunkName: "component---src-pages-paths-privacy-page-index-jsx" */),
  "component---src-pages-paths-services-page-index-jsx": () => import("./../../../src/pages/paths/services_page/index.jsx" /* webpackChunkName: "component---src-pages-paths-services-page-index-jsx" */),
  "component---src-pages-paths-terms-page-index-jsx": () => import("./../../../src/pages/paths/terms_page/index.jsx" /* webpackChunkName: "component---src-pages-paths-terms-page-index-jsx" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-values-js": () => import("./../../../src/pages/values.js" /* webpackChunkName: "component---src-pages-values-js" */)
}

